.logo{
  text-decoration: none;
  color: $grey-dark;
  &--white{
    color:$white;
  }
  .logo__img{
    display:block;
    @media (max-width: $mob) {
      width: 150px;
    }
  }
  .logo__text{
    margin-top:-5px;
    font-family: $font-2;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
    text-align:center;
    text-decoration:none;
  }
}
