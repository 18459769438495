.numbers{
    .numbers__content{
        display:flex;
        justify-content: space-between;
        .content__item{
            width: calc( (100% / 3) - 50px);
            box-sizing: border-box;
            text-align:center;
            .item__icon{
                font-size: 35px;
                color: $grey;
            }
            .item__number{
                font-size:50px;
                font-weight:800;
                color:$primary;
            }
            .item__text{
                color: $black;
                font-weight:bold;
                font-size:22px;
            }
            .item__text-2{
                margin-top:10px;
                color: $grey;
                font-size: 16px;
            }
        }
    }
}