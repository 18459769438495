.features{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  //color:white;
  &__item{
    width:calc(25% - 40px);
    text-align:center;
    @media (max-width: $tab) {
        width: calc(50% - 20px);
        margin:15px 0;
      }
    i{
      font-size:40px;
    }
    span{
      display:block;
      margin-top: 15px;
      ;
    }
  }

}