.text-img {
	display: flex;
	justify-content: space-between;
	align-items: stretch;

	@media (max-width: $tab) {
		flex-direction: column;
		text-align: center;
	}

	& > div {
		width: 50%;
		@media (max-width: $tab) {
			width: 100%;
		}
	}

	&__img {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@media (max-width: $tab) {
			max-height:300px;
			height:80vw;
		}
		 
	}

	&__text {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		box-sizing: border-box;
		.text__content{
			width: 100%;
			max-width: 590px;
			padding-left: 50px;
			padding-right:30px;
			@media (max-width: $tab) {
				padding-left: 30px;
				max-width: 100%;
			}
		}
		.text__cat {
			margin-bottom: 10px;
			font-size: 20px;
			font-weight: bold;
			text-transform: uppercase;
			color: $primary;
		}

		p {
			text-align: justify;

			@media (max-width: $mob) {
				text-align: center;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--text-first {
		flex-direction: row-reverse;

		@media (max-width: $tab) {
			flex-direction: column;
		}
		.text-img__text {
			display: flex;
			justify-content: flex-end;
			.text__content {
				padding-left:30px;
				padding-right: 50px;
				@media (max-width: $tab) {
					padding-left: 30px;
				}
			}
		}
	}

	p,
	ul li {
		font-size: 20px;
	}

	.button  {
		margin-top: 13px;
	}



}