.support{
  padding:30px 0;
  background:$grey-light;
  text-align:center;
  &__icon{
    font-size:30px;
  }
  &__title{
   margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  }
  &__text{
    margin-top:7px;
    font-size:12px;
  }
}