@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@font-face {
  font-family: 'font-icons';
  src: url("../fonts/font-icons.woff2?2044915") format("woff2"), url("../fonts/font-icons.woff?2044915") format("woff");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
	  font-family: 'font-icons';
	  src: url('../font/font-icons.svg?2044915#font-icons') format('svg');
	}
  }
  */
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'font-icons';
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-search:before {
  content: '\e800'; }

/* '' */
.icon-close:before {
  content: '\e801'; }

/* '' */
.icon-twitter:before {
  content: '\f099'; }

/* '' */
.icon-menu:before {
  content: '\f0c9'; }

/* '' */
.icon-linkedin:before {
  content: '\f0e1'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-angle-down:before {
  content: '\f107'; }

/* '' */
.icon-youtube-play:before {
  content: '\f16a'; }

/* '' */
html {
  scroll-behavior: smooth; }

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4;
  color: #212529; }
  @media (max-width: 768px) {
    body {
      font-size: 16px; } }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: normal;
  line-height: 1.2; }

img {
  max-width: 100%;
  height: auto; }

p {
  margin: 15px 0; }
  p a {
    color: #FFC822;
    text-decoration: none; }
  p img {
    max-width: 100%;
    height: auto !important; }

ul, ol {
  padding-left: 22px; }
  ul li, ol li {
    margin-bottom: 10px; }
    ul li:last-child, ol li:last-child {
      margin: 0; }
    ul li ul li:first-child, ol li ul li:first-child {
      margin-top: 10px; }

strong {
  font-weight: 600; }

.center {
  text-align: center; }

.calendly-overlay {
  background: #212529 !important; }

.noscroll {
  overflow-y: hidden;
  margin-right: 17px; }
  @media (max-width: 1023px) {
    .noscroll {
      margin-right: 0; } }

.no-margin {
  margin: 0 !important; }

.center {
  text-align: center; }

.justify-center {
  justify-content: center; }

[v-cloak] {
  display: none; }

.uppercase {
  text-transform: uppercase; }

.disabled {
  color: #6a6a6a; }

@media (max-width: 1023px) {
  .hide-tablet {
    display: none !important; } }

@media (max-width: 768px) {
  .hide-mobile {
    display: none !important; } }

.show-tablet {
  display: none; }
  @media (max-width: 1023px) {
    .show-tablet {
      display: block !important; } }

.show-mobile {
  display: none; }
  @media (max-width: 768px) {
    .show-mobile {
      display: block !important; } }

.grey-light {
  color: #f9fafb; }

.grey-dark {
  color: #212529; }

.white {
  color: #ffffff; }

.primary {
  color: #FFC822; }

.bg-grey-dark {
  background: #212529; }

.bg-grey-light {
  background: #f9fafb; }

.bg-primary {
  background: #FFC822; }

.bg-secondary {
  background: #ff9f22; }

.bg-grey-light-crypto {
  background: url(../images/bg-crypto--light.gif) repeat #f9fafb;
  background-size: 500px;
  background-attachment: fixed; }

.rounded {
  border-radius: 10px; }

.floating {
  animation: floating 6s ease-in-out infinite; }
  @media (max-width: 1023px) {
    .floating {
      animation: none; } }

@keyframes floating {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-40px); }
  100% {
    transform: translatey(0px); } }

.text-big {
  font-size: 22px; }

.img-grey {
  filter: grayscale(1); }

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .row--vertical-center {
    align-items: center; }
  @media (max-width: 768px) {
    .row.mob-no-padding {
      margin-left: 0;
      margin-right: 0; }
      .row.mob-no-padding > div {
        padding-left: 0;
        padding-right: 0; } }
  .row--50 {
    margin-left: -50px;
    margin-right: -50px; }
    @media (max-width: 768px) {
      .row--50 {
        margin-left: -15px;
        margin-right: -15px; } }
    .row--50 .col,
    .row--50 .col-1,
    .row--50 .col-2,
    .row--50 .col-3,
    .row--50 .col-4,
    .row--50 .col-5,
    .row--50 .col-6,
    .row--50 .col-7,
    .row--50 .col-8,
    .row--50 .col-9,
    .row--50 .col-10,
    .row--50 .col-11,
    .row--50 .col-12,
    .row--50 .col-1-5,
    .row--50 .col-2-5,
    .row--50 .col-3-5,
    .row--50 .col-4-5 {
      padding-right: 50px;
      padding-left: 50px; }
      @media (max-width: 768px) {
        .row--50 .col,
        .row--50 .col-1,
        .row--50 .col-2,
        .row--50 .col-3,
        .row--50 .col-4,
        .row--50 .col-5,
        .row--50 .col-6,
        .row--50 .col-7,
        .row--50 .col-8,
        .row--50 .col-9,
        .row--50 .col-10,
        .row--50 .col-11,
        .row--50 .col-12,
        .row--50 .col-1-5,
        .row--50 .col-2-5,
        .row--50 .col-3-5,
        .row--50 .col-4-5 {
          padding-right: 15px;
          padding-left: 15px; } }
    @media (max-width: 1200px) {
      .row--50 .col-desksm-1,
      .row--50 .col-desksm-2,
      .row--50 .col-desksm-3,
      .row--50 .col-desksm-4,
      .row--50 .col-desksm-5,
      .row--50 .col-desksm-6,
      .row--50 .col-desksm-7,
      .row--50 .col-desksm-8,
      .row--50 .col-desksm-9,
      .row--50 .col-desksm-10,
      .row--50 .col-desksm-11,
      .row--50 .col-desksm-12,
      .row--50 .col-desksm-1-5,
      .row--50 .col-desksm-2-5,
      .row--50 .col-desksm-3-5,
      .row--50 .col-desksm-4-5 {
        padding-right: 50px;
        padding-left: 50px; } }
    @media (max-width: 1023px) {
      .row--50 .col-tab-1,
      .row--50 .col-tab-2,
      .row--50 .col-tab-3,
      .row--50 .col-tab-4,
      .row--50 .col-tab-5,
      .row--50 .col-tab-6,
      .row--50 .col-tab-7,
      .row--50 .col-tab-8,
      .row--50 .col-tab-9,
      .row--50 .col-tab-10,
      .row--50 .col-tab-11,
      .row--50 .col-tab-12,
      .row--50 .col-tab-1-5,
      .row--50 .col-tab-2-5,
      .row--50 .col-tab-3-5,
      .row--50 .col-tab-4-5 {
        padding-right: 50px;
        padding-left: 50px; } }
  @media (max-width: 1023px) and (max-width: 768px) {
    .row--50 .col-tab-1,
    .row--50 .col-tab-2,
    .row--50 .col-tab-3,
    .row--50 .col-tab-4,
    .row--50 .col-tab-5,
    .row--50 .col-tab-6,
    .row--50 .col-tab-7,
    .row--50 .col-tab-8,
    .row--50 .col-tab-9,
    .row--50 .col-tab-10,
    .row--50 .col-tab-11,
    .row--50 .col-tab-12,
    .row--50 .col-tab-1-5,
    .row--50 .col-tab-2-5,
    .row--50 .col-tab-3-5,
    .row--50 .col-tab-4-5 {
      padding-right: 15px;
      padding-left: 15px; } }
    @media (max-width: 768px) {
      .row--50 .col-mob-1,
      .row--50 .col-mob-2,
      .row--50 .col-mob-3,
      .row--50 .col-mob-4,
      .row--50 .col-mob-5,
      .row--50 .col-mob-6,
      .row--50 .col-mob-7,
      .row--50 .col-mob-8,
      .row--50 .col-mob-9,
      .row--50 .col-mob-10,
      .row--50 .col-mob-11,
      .row--50 .col-mob-12,
      .row--50 .col-mob-1-5,
      .row--50 .col-mob-2-5,
      .row--50 .col-mob-3-5,
      .row--50 .col-mob-4-5 {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: 15px;
        padding-left: 15px; } }

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-1-5,
.col-2-5,
.col-3-5,
.col-4-5 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.col-1 {
  flex-basis: 8.33333333%;
  max-width: 8.33333333%; }

.col-2 {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%; }

.col-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-4 {
  flex-basis: 33.33333333%;
  max-width: 33.33333333%; }

.col-5 {
  flex-basis: 41.66666667%;
  max-width: 41.66666667%; }

.col-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-7 {
  flex-basis: 58.33333333%;
  max-width: 58.33333333%; }

.col-8 {
  flex-basis: 66.66666667%;
  max-width: 66.66666667%; }

.col-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-10 {
  flex-basis: 83.33333333%;
  max-width: 83.33333333%; }

.col-11 {
  flex-basis: 91.66666667%;
  max-width: 91.66666667%; }

.col-12 {
  flex-basis: 100%;
  max-width: 100%; }

.col-1-5 {
  flex-basis: 20%;
  max-width: 20%; }

.col-2-5 {
  flex-basis: 40%;
  max-width: 40%; }

.col-3-5 {
  flex-basis: 60%;
  max-width: 60%; }

.col-4-5 {
  flex-basis: 80%;
  max-width: 80%; }

@media (max-width: 1200px) {
  .col-desksm-1,
  .col-desksm-2,
  .col-desksm-3,
  .col-desksm-4,
  .col-desksm-5,
  .col-desksm-6,
  .col-desksm-7,
  .col-desksm-8,
  .col-desksm-9,
  .col-desksm-10,
  .col-desksm-11,
  .col-desksm-12,
  .col-desksm-1-5,
  .col-desksm-2-5,
  .col-desksm-3-5,
  .col-desksm-4-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px; }
  .col-desksm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-desksm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-desksm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-desksm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-desksm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-desksm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-desksm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-desksm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-desksm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-desksm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-desksm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-desksm-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-desksm-1-5 {
    flex-basis: 20%;
    max-width: 20%; }
  .col-desksm-2-5 {
    flex-basis: 40%;
    max-width: 40%; }
  .col-desksm-3-5 {
    flex-basis: 60%;
    max-width: 60%; }
  .col-desksm-4-5 {
    flex-basis: 80%;
    max-width: 80%; } }

@media (max-width: 1023px) {
  .col-tab-1,
  .col-tab-2,
  .col-tab-3,
  .col-tab-4,
  .col-tab-5,
  .col-tab-6,
  .col-tab-7,
  .col-tab-8,
  .col-tab-9,
  .col-tab-10,
  .col-tab-11,
  .col-tab-12,
  .col-tab-1-5,
  .col-tab-2-5,
  .col-tab-3-5,
  .col-tab-4-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px; }
  .col-tab-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-tab-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-tab-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-tab-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-tab-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-tab-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-tab-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-tab-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-tab-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-tab-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-tab-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-tab-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-tab-1-5 {
    flex-basis: 20%;
    max-width: 20%; }
  .col-tab-2-5 {
    flex-basis: 40%;
    max-width: 40%; }
  .col-tab-3-5 {
    flex-basis: 60%;
    max-width: 60%; }
  .col-tab-4-5 {
    flex-basis: 80%;
    max-width: 80%; } }

@media (max-width: 768px) {
  .col-mob-1,
  .col-mob-2,
  .col-mob-3,
  .col-mob-4,
  .col-mob-5,
  .col-mob-6,
  .col-mob-7,
  .col-mob-8,
  .col-mob-9,
  .col-mob-10,
  .col-mob-11,
  .col-mob-12,
  .col-mob-1-5,
  .col-mob-2-5,
  .col-mob-3-5,
  .col-mob-4-5 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 15px;
    padding-left: 15px; }
  .col-mob-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%; }
  .col-mob-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%; }
  .col-mob-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-mob-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%; }
  .col-mob-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%; }
  .col-mob-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-mob-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%; }
  .col-mob-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%; }
  .col-mob-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-mob-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%; }
  .col-mob-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%; }
  .col-mob-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-mob-1-5 {
    flex-basis: 20%;
    max-width: 20%; }
  .col-mob-2-5 {
    flex-basis: 40%;
    max-width: 40%; }
  .col-mob-3-5 {
    flex-basis: 60%;
    max-width: 60%; }
  .col-mob-4-5 {
    flex-basis: 80%;
    max-width: 80%; } }

.main-wrap {
  width: 100%;
  margin: auto; }

.wrap {
  width: 100%;
  max-width: 1340px;
  margin: auto;
  padding: 0 30px;
  box-sizing: border-box; }
  @media (max-width: 768px) {
    .wrap {
      padding: 0 15px; } }

.animate {
  opacity: 0;
  transition: all 0.25s; }
  .animate.to-top {
    transform: translateY(100px); }
  .animate.to-bottom {
    transform: translateY(-100px); }
  .animate.to-left {
    transform: translateX(-100px); }
  .animate.to-right {
    transform: translateX(100px); }
  .animate.animated {
    opacity: 1;
    transform: translate(0); }
  @media (max-width: 768px) {
    .animate {
      opacity: 1;
      transform: translate(0); } }

.logo {
  text-decoration: none;
  color: #212529; }
  .logo--white {
    color: #ffffff; }
  .logo .logo__img {
    display: block; }
    @media (max-width: 768px) {
      .logo .logo__img {
        width: 150px; } }
  .logo .logo__text {
    margin-top: -5px;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;
    text-decoration: none; }

.button {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  height: 35px;
  margin-right: 20px;
  box-sizing: border-box;
  padding: 5px 15px;
  background: #FFC822;
  border: 2px solid #FFC822;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s; }
  .button:last-child {
    margin-right: 0; }
  .button:hover {
    background: #ffaa00;
    border-color: #ffaa00; }
  .button--outline {
    background: transparent;
    color: #FFC822; }
    .button--outline:hover {
      color: #212529; }
  .button--black {
    background: #000000;
    border-color: #000000;
    color: #ffffff; }
    .button--black:hover {
      color: #212529; }
  .button--grey {
    background: #212529;
    border-color: #212529;
    color: #ffffff; }
    .button--grey:hover {
      color: #212529; }
  .button--grey-outline {
    background: transparent;
    border-color: #212529;
    color: #212529; }
  .button--l {
    height: 50px;
    padding: 8px 25px;
    font-size: 20px; }
    @media (max-width: 768px) {
      .button--l {
        padding: 5px 15px; } }
  .button--xl {
    height: 65px;
    padding: 10px 35px;
    font-size: 24px; }

.buttons-container {
  margin-top: 25px; }
  @media (max-width: 768px) {
    .buttons-container .button:first-child {
      margin-bottom: 10px; } }
  @media (max-width: 768px) {
    .buttons-container .button:last-child {
      margin-bottom: 0; } }

.title-xl {
  font-size: 60px;
  line-height: 1.2;
  font-weight: 700; }
  @media (max-width: 1023px) {
    .title-xl {
      font-size: 40px; } }
  @media (max-width: 768px) {
    .title-xl {
      font-size: 36px; } }

.title-l {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    .title-l {
      font-size: 32px; } }

.title-m {
  font-size: 34px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase; }

.title-s {
  margin-top: 50px;
  font-size: 28px;
  line-height: 1.2;
  font-weight: 700; }
  .title-s:first-child {
    margin-top: 0; }
  @media (max-width: 768px) {
    .title-s {
      font-size: 24px; } }

.title-xs {
  margin-top: 35px;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700; }
  @media (max-width: 768px) {
    .title-xs {
      font-size: 20px; } }
  .title-xs:first-child {
    margin-top: 0; }

.separator {
  height: 80px; }
  @media (max-width: 768px) {
    .separator {
      height: 55px; } }
  .separator--md {
    height: 65px; }
    @media (max-width: 768px) {
      .separator--md {
        height: 40px; } }
  .separator--sm {
    height: 50px; }
    @media (max-width: 768px) {
      .separator--sm {
        height: 30px; } }
  .separator--xsm {
    height: 30px; }

.citation {
  position: relative;
  padding: 0 60px; }
  @media (max-width: 768px) {
    .citation {
      padding: 0 0 0 30px; } }
  .citation::before {
    position: absolute;
    left: 0;
    top: -25px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f10d";
    font-size: 40px; }
    @media (max-width: 768px) {
      .citation::before {
        top: -12px;
        font-size: 25px; } }
  .citation__text {
    font-size: 22px;
    font-style: italic; }
    @media (max-width: 768px) {
      .citation__text {
        font-size: 19px; } }
    .citation__text p {
      margin-top: 0; }
  .citation__author {
    font-size: 24px;
    font-weight: bold; }
    @media (max-width: 768px) {
      .citation__author {
        font-size: 20px; } }

.coach__content {
  display: flex;
  align-items: center;
  margin-top: 50px; }
  @media (max-width: 768px) {
    .coach__content {
      flex-direction: column; } }
  .coach__content .content__img img {
    width: 200px;
    height: auto;
    border-radius: 108px;
    margin-right: 50px;
    border: 4px solid #f9fafb; }
    @media (max-width: 768px) {
      .coach__content .content__img img {
        margin: 0 0 30px 0; } }
  .coach__content .content__text {
    width: calc(100% - 258px); }
    @media (max-width: 768px) {
      .coach__content .content__text {
        width: auto; } }

.centres-formations {
  background: url(../images/centre-formations--white.jpg) no-repeat center #f9fafb;
  background-attachment: fixed;
  padding: 70px 0;
  text-align: center; }
  @media (max-width: 768px) {
    .centres-formations {
      padding: 50px 0;
      background: #f9fafb; } }
  .centres-formations .wrap {
    max-width: 900px; }
  .centres-formations p {
    font-size: 22px; }
  .centres-formations .button {
    margin-top: 13px; }

.features {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .features__item {
    width: calc(25% - 40px);
    text-align: center; }
    @media (max-width: 1023px) {
      .features__item {
        width: calc(50% - 20px);
        margin: 15px 0; } }
    .features__item i {
      font-size: 40px; }
    .features__item span {
      display: block;
      margin-top: 15px; }

.pdv__intro {
  padding: 90px 0 50px 0;
  background: #212529; }
  @media (max-width: 768px) {
    .pdv__intro {
      padding: 70px 0 35px 0; } }
  .pdv__intro .intro__suptitle {
    color: #FFC822;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px; }
  .pdv__intro .intro__text {
    margin-top: 15px;
    font-size: 20px;
    color: #ffffff; }
    @media (max-width: 768px) {
      .pdv__intro .intro__text {
        font-size: 18px; } }
  .pdv__intro .intro__maj {
    margin-top: 20px;
    font-size: 14px;
    color: #ffffff; }
  .pdv__intro .intro__title {
    text-transform: uppercase;
    font-size: 46px;
    color: white; }
    @media (max-width: 768px) {
      .pdv__intro .intro__title {
        font-size: 32px; } }
    @media (max-width: 375px) {
      .pdv__intro .intro__title {
        font-size: 30px; } }

.pdv__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.pdv__left {
  width: calc(100% - 480px); }
  @media (max-width: 1023px) {
    .pdv__left {
      width: 100%; } }
  .pdv__left .title--3 {
    margin-top: 50px; }
    .pdv__left .title--3:first-child {
      margin-top: 0; }
  .pdv__left .check {
    position: relative;
    padding-left: 30px; }
    .pdv__left .check:before {
      position: absolute;
      top: 1px;
      left: 0;
      font-family: "Font Awesome 6 Free";
      content: "\f00c";
      font-weight: 900;
      color: #42be35; }

.pdv__right {
  width: 400px;
  margin-top: -310px;
  margin-bottom: -200px; }
  @media (max-width: 1023px) {
    .pdv__right {
      width: 100%;
      margin-top: 50px;
      margin-bottom: auto; } }

.pdv__video {
  margin-top: 50px; }
  .pdv__video iframe,
  .pdv__video video {
    display: block;
    width: 100%;
    height: 450px;
    border-radius: 10px;
    border: 0; }
    @media (max-width: 1023px) {
      .pdv__video iframe,
      .pdv__video video {
        height: 50vw; } }

.pdv__informations {
  position: sticky;
  top: 120px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px; }
  @media (max-width: 1023px) {
    .pdv__informations {
      position: relative;
      top: 0; } }
  .pdv__informations .informations__sep {
    display: block;
    width: 150px;
    margin: 20px auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  .pdv__informations .informations__title {
    font-weight: bold;
    text-align: center;
    font-size: 22px; }
  .pdv__informations .informations__price {
    margin-bottom: -15px;
    text-align: center;
    color: #FFC822;
    font-weight: 700;
    font-size: 60px; }
    .pdv__informations .informations__price sup {
      font-size: 24px; }
    .pdv__informations .informations__price .prix-barre {
      color: #212529;
      font-size: 30px;
      text-decoration: line-through;
      font-weight: 600; }
  .pdv__informations .informations__list .list__item {
    display: flex;
    margin-bottom: 8px; }
    .pdv__informations .informations__list .list__item .item__icon {
      width: 35px; }
  .pdv__informations .informations__start {
    text-align: center; }
    .pdv__informations .informations__start .start__ou {
      margin: 8px 0 0 0;
      font-size: 14px; }
    .pdv__informations .informations__start .start__free {
      font-size: 16px; }
      .pdv__informations .informations__start .start__free a {
        color: #FFC822; }
  .pdv__informations-mobile {
    display: none; }
    @media (max-width: 1023px) {
      .pdv__informations-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 75px;
        box-sizing: border-box;
        padding: 0 15px;
        background: #ffffff;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        z-index: 2; } }
    .pdv__informations-mobile .button {
      margin-left: 15px; }
    .pdv__informations-mobile .informations-mobile__title {
      font-weight: 600;
      font-size: 16px; }
    .pdv__informations-mobile .informations-mobile__price {
      margin-top: -5px;
      color: #FFC822;
      font-weight: bold;
      font-size: 26px; }
      .pdv__informations-mobile .informations-mobile__price sup {
        text-transform: uppercase;
        font-size: 11px; }
      .pdv__informations-mobile .informations-mobile__price .prix-barre {
        color: #212529;
        font-size: 18px;
        text-decoration: line-through;
        font-weight: 600; }

.support {
  padding: 30px 0;
  background: #f9fafb;
  text-align: center; }
  .support__icon {
    font-size: 30px; }
  .support__title {
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold; }
  .support__text {
    margin-top: 7px;
    font-size: 12px; }

.text-img {
  display: flex;
  justify-content: space-between;
  align-items: stretch; }
  @media (max-width: 1023px) {
    .text-img {
      flex-direction: column;
      text-align: center; } }
  .text-img > div {
    width: 50%; }
    @media (max-width: 1023px) {
      .text-img > div {
        width: 100%; } }
  .text-img__img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    @media (max-width: 1023px) {
      .text-img__img {
        max-height: 300px;
        height: 80vw; } }
  .text-img__text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box; }
    .text-img__text .text__content {
      width: 100%;
      max-width: 590px;
      padding-left: 50px;
      padding-right: 30px; }
      @media (max-width: 1023px) {
        .text-img__text .text__content {
          padding-left: 30px;
          max-width: 100%; } }
    .text-img__text .text__cat {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      color: #FFC822; }
    .text-img__text p {
      text-align: justify; }
      @media (max-width: 768px) {
        .text-img__text p {
          text-align: center; } }
      .text-img__text p:last-child {
        margin-bottom: 0; }
  .text-img--text-first {
    flex-direction: row-reverse; }
    @media (max-width: 1023px) {
      .text-img--text-first {
        flex-direction: column; } }
    .text-img--text-first .text-img__text {
      display: flex;
      justify-content: flex-end; }
      .text-img--text-first .text-img__text .text__content {
        padding-left: 30px;
        padding-right: 50px; }
        @media (max-width: 1023px) {
          .text-img--text-first .text-img__text .text__content {
            padding-left: 30px; } }
  .text-img p,
  .text-img ul li {
    font-size: 20px; }
  .text-img .button {
    margin-top: 13px; }

.title-xl {
  font-size: 60px;
  line-height: 1.2;
  font-weight: 700; }
  @media (max-width: 1023px) {
    .title-xl {
      font-size: 40px; } }
  @media (max-width: 768px) {
    .title-xl {
      font-size: 36px; } }

.title-l {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase; }
  @media (max-width: 768px) {
    .title-l {
      font-size: 32px; } }

.title-m {
  font-size: 34px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase; }

.title-s {
  margin-top: 50px;
  font-size: 28px;
  line-height: 1.2;
  font-weight: 700; }
  .title-s:first-child {
    margin-top: 0; }
  @media (max-width: 768px) {
    .title-s {
      font-size: 24px; } }

.title-xs {
  margin-top: 35px;
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700; }
  @media (max-width: 768px) {
    .title-xs {
      font-size: 20px; } }
  .title-xs:first-child {
    margin-top: 0; }

.pre-title {
  margin-bottom: 5px;
  color: #FFC822;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase; }

.chapo {
  text-align: center; }
  .chapo .wrap {
    max-width: 860px; }
  .chapo__title {
    font-size: 36px; }
  .chapo p {
    font-size: 22px; }
    @media (max-width: 768px) {
      .chapo p {
        font-size: 19px; } }

.page-404 .title {
  font-size: 105px; }

.page-404 p {
  margin-top: 20px;
  font-size: 24px; }

.page-404 .button {
  margin-top: 50px; }

.page-template-formation,
.single-formation-video {
  padding: 0; }
  .page-template-formation .header,
  .single-formation-video .header {
    display: none; }

.template-formation__aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  height: 100vh;
  background: #212529;
  box-shadow: 3px 10px 9px 0px rgba(0, 0, 0, 0.3); }
  @media (max-width: 1200px) {
    .template-formation__aside {
      transition: all 0.2s;
      left: -350px;
      z-index: 2;
      box-shadow: 3px 10px 9px 0px rgba(0, 0, 0, 0); } }
  @media (max-width: 768px) {
    .template-formation__aside {
      width: 320px;
      left: -320px; } }
  @media (max-width: 1200px) {
    .template-formation__aside.open {
      left: 0;
      box-shadow: 3px 10px 9px 0px rgba(0, 0, 0, 0.3); } }
  .template-formation__aside .aside__button {
    position: absolute;
    top: 0;
    right: -40px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background: #212529;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.2s; }
    .template-formation__aside .aside__button:hover {
      background: #FFC822; }
    @media (max-width: 1200px) {
      .template-formation__aside .aside__button {
        display: flex; } }
    .template-formation__aside .aside__button .button__close {
      display: none; }
    .template-formation__aside .aside__button.open .button__open {
      display: none; }
    .template-formation__aside .aside__button.open .button__close {
      display: block; }
  .template-formation__aside .aside__logo {
    padding: 0 15px;
    display: flex;
    align-items: center;
    height: 90px; }
  .template-formation__aside .aside__retour {
    margin: 10px 15px 20px 15px; }
    .template-formation__aside .aside__retour a {
      color: #ffffff;
      font-size: 15px;
      text-decoration: none;
      transition: all 0.2s; }
      .template-formation__aside .aside__retour a:hover {
        color: #FFC822; }
  .template-formation__aside .aside__sommaire {
    overflow-y: auto;
    height: calc(100vh - 190px);
    color: #ffffff;
    margin: 15px;
    padding: 15px;
    border-radius: 6px;
    background: #2a2f34; }
    .template-formation__aside .aside__sommaire .menu {
      list-style: none;
      padding-left: 0; }
      .template-formation__aside .aside__sommaire .menu a {
        color: white;
        text-decoration: none;
        transition: all 0.2s;
        font-weight: 600; }
        .template-formation__aside .aside__sommaire .menu a:hover {
          color: #FFC822; }
      .template-formation__aside .aside__sommaire .menu li {
        margin-bottom: 14px;
        font-size: 15px; }
        .template-formation__aside .aside__sommaire .menu li.current-menu-item > a {
          color: #FFC822; }
        .template-formation__aside .aside__sommaire .menu li .sub-menu li {
          margin: 4px 0;
          font-size: 14px; }
          .template-formation__aside .aside__sommaire .menu li .sub-menu li a {
            font-weight: 400; }

.template-formation__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-left: 350px;
  padding: 50px 30px 100px; }
  @media (max-width: 1200px) {
    .template-formation__main {
      margin-left: 0; } }
  @media (max-width: 768px) {
    .template-formation__main {
      padding: 50px 15px 100px; } }
  .template-formation__main--intro {
    height: 100vh;
    text-align: center; }
    .template-formation__main--intro .main__content .content__title {
      text-align: center !important; }
    .template-formation__main--intro .content__num {
      width: 70px;
      height: 70px;
      border-radius: 100px;
      color: white;
      background: #FFC822;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      margin-bottom: 40px; }
      @media (max-width: 768px) {
        .template-formation__main--intro .content__num {
          width: 50px;
          height: 50px;
          font-size: 24px;
          margin-bottom: 30px; } }
  .template-formation__main .main__content {
    max-width: 1000px;
    width: 100%;
    margin: auto; }
    .template-formation__main .main__content .content__title {
      text-align: left; }
      .template-formation__main .main__content .content__title span {
        font-weight: 600; }
    .template-formation__main .main__content .content__video {
      position: relative;
      width: 100%;
      margin-top: 40px;
      aspect-ratio: 16 / 9; }
      .template-formation__main .main__content .content__video iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px; }
    .template-formation__main .main__content .content__text {
      margin-top: 40px; }
    .template-formation__main .main__content .content__quiz {
      margin: 50px 30px;
      padding: 30px 0 20px;
      background: #f9fafb;
      border-radius: 10px; }
      @media (max-width: 768px) {
        .template-formation__main .main__content .content__quiz {
          margin: 50px 0;
          padding: 30px 15px 20px; } }
      .template-formation__main .main__content .content__quiz .quiz__item {
        padding: 0 30px; }
        .template-formation__main .main__content .content__quiz .quiz__item .item__numero {
          text-transform: uppercase;
          font-weight: 600;
          color: #FFC822;
          font-size: 16px; }
          @media (max-width: 768px) {
            .template-formation__main .main__content .content__quiz .quiz__item .item__numero {
              font-size: 14px; } }
        .template-formation__main .main__content .content__quiz .quiz__item .item__question {
          font-size: 25px;
          font-weight: bold;
          margin-bottom: 25px; }
          @media (max-width: 768px) {
            .template-formation__main .main__content .content__quiz .quiz__item .item__question {
              font-size: 21px; } }
        .template-formation__main .main__content .content__quiz .quiz__item .item__proposition {
          position: relative;
          margin-top: 15px; }
          .template-formation__main .main__content .content__quiz .quiz__item .item__proposition .proposition__label {
            position: relative;
            display: block;
            font-size: 18px;
            background: #ffffff;
            border: 1px solid #ffffff;
            padding: 20px 50px 20px 15px;
            border-radius: 10px;
            transition: all 0.2s; }
            @media (max-width: 768px) {
              .template-formation__main .main__content .content__quiz .quiz__item .item__proposition .proposition__label {
                padding: 13px 50px 13px 10px;
                font-size: 16px; } }
            .template-formation__main .main__content .content__quiz .quiz__item .item__proposition .proposition__label:after {
              content: "\f00d";
              position: absolute;
              top: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50px;
              height: 100%;
              font-family: "Font Awesome 6 Free";
              font-weight: 900;
              color: #bd0000;
              font-size: 30px;
              opacity: 0;
              transition: all 0.2s; }
              @media (max-width: 768px) {
                .template-formation__main .main__content .content__quiz .quiz__item .item__proposition .proposition__label:after {
                  width: 40px;
                  font-size: 22px; } }
            .template-formation__main .main__content .content__quiz .quiz__item .item__proposition .proposition__label.true:after {
              content: "\f00c";
              color: #42be35; }
          .template-formation__main .main__content .content__quiz .quiz__item .item__proposition label {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer; }
          .template-formation__main .main__content .content__quiz .quiz__item .item__proposition input {
            position: relative;
            display: block;
            margin-right: 10px;
            appearance: none;
            cursor: pointer; }
            .template-formation__main .main__content .content__quiz .quiz__item .item__proposition input:checked + .proposition__label {
              background: #fff0f0;
              color: #bd0000;
              border-color: #bd0000; }
              .template-formation__main .main__content .content__quiz .quiz__item .item__proposition input:checked + .proposition__label:after {
                opacity: 1; }
              .template-formation__main .main__content .content__quiz .quiz__item .item__proposition input:checked + .proposition__label.true {
                background: #f0fff0;
                color: #42be35;
                border-color: #42be35; }
                .template-formation__main .main__content .content__quiz .quiz__item .item__proposition input:checked + .proposition__label.true:after {
                  opacity: 1; }
        .template-formation__main .main__content .content__quiz .quiz__item .item__button {
          margin-top: 50px; }
        .template-formation__main .main__content .content__quiz .quiz__item .item__end {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .template-formation__main .main__content .content__quiz .quiz__item .item__end .end__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 140px;
            height: 140px;
            border-radius: 150px;
            background: #fff;
            border: 4px solid #FFC822;
            font-size: 60px; }
          .template-formation__main .main__content .content__quiz .quiz__item .item__end .end__title {
            margin-top: 20px;
            font-size: 34px;
            font-weight: bold; }
          .template-formation__main .main__content .content__quiz .quiz__item .item__end .end__button {
            margin: 30px 0 15px; }
      .template-formation__main .main__content .content__quiz .quiz__nav {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        padding: 20px 30px 0 30px;
        border-top: 1px solid #b7bec5; }
        .template-formation__main .main__content .content__quiz .quiz__nav .swiper-button-disabled {
          opacity: 0; }
        .template-formation__main .main__content .content__quiz .quiz__nav .hidden {
          opacity: 0;
          visibility: hidden; }
        @media (max-width: 768px) {
          .template-formation__main .main__content .content__quiz .quiz__nav .nav__prev {
            margin-left: 12px; } }
        @media (max-width: 768px) {
          .template-formation__main .main__content .content__quiz .quiz__nav .nav__prev span {
            display: none; } }
        @media (max-width: 768px) {
          .template-formation__main .main__content .content__quiz .quiz__nav .button span {
            display: none; } }
        @media (max-width: 768px) {
          .template-formation__main .main__content .content__quiz .quiz__nav .button i {
            margin: 0; } }
        .template-formation__main .main__content .content__quiz .quiz__nav .nav__bullets {
          position: absolute;
          left: calc(50% - 48px);
          display: flex; }
          @media (max-width: 768px) {
            .template-formation__main .main__content .content__quiz .quiz__nav .nav__bullets {
              left: calc(50% - 36px); } }
          .template-formation__main .main__content .content__quiz .quiz__nav .nav__bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
            background: #FFC822; }
          .template-formation__main .main__content .content__quiz .quiz__nav .nav__bullets .bullet {
            width: 8px;
            height: 8px;
            margin: 0 3px;
            border-radius: 10px;
            background: #ffffff;
            border: 1px solid #b7bec5;
            transition: all 0.2s;
            cursor: pointer; }
            @media (max-width: 768px) {
              .template-formation__main .main__content .content__quiz .quiz__nav .nav__bullets .bullet {
                margin: 0 1px; } }
            .template-formation__main .main__content .content__quiz .quiz__nav .nav__bullets .bullet.active, .template-formation__main .main__content .content__quiz .quiz__nav .nav__bullets .bullet:hover {
              border-color: #FFC822;
              background: #FFC822; }

.template-formation__nav {
  position: fixed;
  bottom: 0;
  left: 350px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 350px);
  box-sizing: border-box;
  height: 80px;
  padding: 0 25px;
  background-color: #ffffff;
  color: #ffffff; }
  @media (max-width: 1200px) {
    .template-formation__nav {
      left: 0;
      width: 100%; } }
  @media (max-width: 768px) {
    .template-formation__nav {
      padding: 0 10px; } }
  .template-formation__nav .nav__title {
    margin: 0 15px;
    color: #212529;
    font-weight: 600;
    text-align: center; }
    @media (max-width: 768px) {
      .template-formation__nav .nav__title {
        font-size: 13px; } }
    .template-formation__nav .nav__title div {
      font-size: 12px; }
      @media (max-width: 768px) {
        .template-formation__nav .nav__title div {
          font-size: 10px; } }
  @media (max-width: 1023px) {
    .template-formation__nav .button span {
      display: none; } }
  @media (max-width: 1023px) {
    .template-formation__nav .button i {
      margin: 0; } }

.logos-clients {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px 40px; }

.numbers .numbers__content {
  display: flex;
  justify-content: space-between; }
  .numbers .numbers__content .content__item {
    width: calc( (100% / 3) - 50px);
    box-sizing: border-box;
    text-align: center; }
    .numbers .numbers__content .content__item .item__icon {
      font-size: 35px;
      color: #6a6a6a; }
    .numbers .numbers__content .content__item .item__number {
      font-size: 50px;
      font-weight: 800;
      color: #FFC822; }
    .numbers .numbers__content .content__item .item__text {
      color: #000000;
      font-weight: bold;
      font-size: 22px; }
    .numbers .numbers__content .content__item .item__text-2 {
      margin-top: 10px;
      color: #6a6a6a;
      font-size: 16px; }

.hero {
  overflow: hidden;
  background: url(../images/bg-3.jpg) no-repeat center top;
  background-attachment: fixed; }
  .hero .hero__overlay {
    padding-top: 50px;
    background-color: rgba(0, 0, 0, 0.8);
    height: 530px;
    display: flex;
    align-items: center; }
  .hero .hero__content {
    position: relative;
    display: flex;
    align-items: center; }
    .hero .hero__content .content__text {
      box-sizing: border-box;
      padding-right: 40px;
      color: #ffffff; }
      .hero .hero__content .content__text p {
        font-size: 20px;
        max-width: 780px;
        color: #ffffff; }
        @media (max-width: 768px) {
          .hero .hero__content .content__text p {
            font-size: 16px; } }
      .hero .hero__content .content__text .text__buttons {
        margin-top: 40px; }
        .hero .hero__content .content__text .text__buttons .button:first-child {
          margin-right: 20px; }
          @media (max-width: 768px) {
            .hero .hero__content .content__text .text__buttons .button:first-child {
              margin-bottom: 10px; } }
    .hero .hero__content .content__img {
      position: absolute;
      top: 0;
      right: 0;
      width: 30%;
      box-sizing: border-box;
      padding-left: 40px; }
      .hero .hero__content .content__img img {
        display: block;
        width: 100%;
        height: auto; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  background: transparent;
  transition: all 0.2s; }
  .header.active {
    background: #212529;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); }
  @media (max-width: 1200px) {
    .header {
      background: #212529;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); } }
  .header .wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    max-width: 100%; }
    @media (max-width: 768px) {
      .header .wrap {
        height: 60px; } }
  .header .logo {
    margin-right: 50px; }
  .header .menu-burger {
    display: none;
    cursor: pointer;
    transition: all 0.25s;
    font-size: 35px;
    color: #ffffff; }
    .header .menu-burger:hover {
      color: #FFC822; }
      @media (max-width: 1023px) {
        .header .menu-burger:hover {
          color: #ffffff; } }
    @media (max-width: 1200px) {
      .header .menu-burger {
        display: flex;
        width: 50px;
        height: 50px;
        align-items: center;
        justify-content: flex-end; } }
    .header .menu-burger .menu-open {
      display: block; }
    .header .menu-burger .menu-close {
      display: none; }
    .header .menu-burger.active .menu-open {
      display: none; }
    .header .menu-burger.active .menu-close {
      display: block; }
  .header .main-menu {
    display: flex;
    align-items: center; }
    .header .main-menu .menu__content {
      display: flex;
      align-items: center; }
      @media (max-width: 1200px) {
        .header .main-menu .menu__content {
          display: none;
          position: fixed;
          top: 90px;
          left: 0;
          flex-direction: column;
          width: 100%;
          background: #212529;
          overflow: hidden;
          transition: all 0.25s;
          border-bottom: 1px solid #000000; } }
      @media (max-width: 768px) {
        .header .main-menu .menu__content {
          top: 60px; } }
      .header .main-menu .menu__content.open {
        display: block; }
      .header .main-menu .menu__content .menu {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0; }
        @media (max-width: 1200px) {
          .header .main-menu .menu__content .menu {
            flex-direction: column; } }
        .header .main-menu .menu__content .menu li {
          margin: 0 30px 0 0; }
          @media (max-width: 1200px) {
            .header .main-menu .menu__content .menu li {
              margin: 20px 0;
              text-align: center; } }
          .header .main-menu .menu__content .menu li a, .header .main-menu .menu__content .menu li .contact-link {
            position: relative;
            color: #ffffff;
            text-decoration: none;
            transition: all 0.25s;
            font-size: 17px;
            cursor: pointer; }
            @media (max-width: 1200px) {
              .header .main-menu .menu__content .menu li a, .header .main-menu .menu__content .menu li .contact-link {
                font-size: 22px; } }
            .header .main-menu .menu__content .menu li a:after, .header .main-menu .menu__content .menu li .contact-link:after {
              position: absolute;
              top: 100%;
              left: 0;
              display: block;
              content: '';
              width: 0;
              height: 2px;
              background: #FFC822;
              transition: all 0.25s; }
            .header .main-menu .menu__content .menu li a:hover:after, .header .main-menu .menu__content .menu li a.active:after, .header .main-menu .menu__content .menu li .contact-link:hover:after, .header .main-menu .menu__content .menu li .contact-link.active:after {
              width: 100%; }
          .header .main-menu .menu__content .menu li.current_page_item a:after {
            width: 100%; }
        .header .main-menu .menu__content .menu .menu-item-has-children.current_page_item .sub-menu a:after {
          width: 0; }
        .header .main-menu .menu__content .menu .menu-item-has-children.current_page_item .sub-menu a:hover:after {
          width: 100%; }
        .header .main-menu .menu__content .menu .menu-item-has-children.current_page_item .sub-menu .current_page_item a:after {
          width: 100%; }
        .header .main-menu .menu__content .menu .menu-item-has-children .sub-menu {
          position: absolute;
          transform: translateX(-20px);
          visibility: hidden;
          opacity: 0;
          transition: all 0.25s;
          background-color: #ffffff;
          z-index: 11;
          list-style: none;
          padding: 20px;
          box-sizing: border-box;
          border-radius: 10px;
          overflow: hidden; }
          @media (max-width: 1200px) {
            .header .main-menu .menu__content .menu .menu-item-has-children .sub-menu {
              position: relative;
              visibility: visible;
              opacity: 1;
              height: auto;
              text-align: center;
              padding: 0;
              transform: translateX(0); } }
          .header .main-menu .menu__content .menu .menu-item-has-children .sub-menu .menu-item {
            margin-top: 20px; }
            .header .main-menu .menu__content .menu .menu-item-has-children .sub-menu .menu-item:first-child {
              margin-top: 0; }
              @media (max-width: 1200px) {
                .header .main-menu .menu__content .menu .menu-item-has-children .sub-menu .menu-item:first-child {
                  margin-top: 20px; } }
        .header .main-menu .menu__content .menu .menu-item-has-children > a:hover:after {
          width: 0; }
        .header .main-menu .menu__content .menu .menu-item-has-children:hover .sub-menu {
          visibility: visible;
          opacity: 1; }
          @media (max-width: 1200px) {
            .header .main-menu .menu__content .menu .menu-item-has-children:hover .sub-menu {
              height: auto; } }
      .header .main-menu .menu__content .link-cart {
        color: #212529;
        transition: all 0.25s;
        margin: 0 30px 0 0; }
        .header .main-menu .menu__content .link-cart:hover {
          color: #FFC822; }
        @media (max-width: 1200px) {
          .header .main-menu .menu__content .link-cart {
            display: none; } }
      @media (max-width: 1200px) {
        .header .main-menu .menu__content .button {
          margin: 20px 0 30px 0;
          font-size: 22px; } }

.footer {
  padding: 50px 0;
  background: #212529;
  color: #ffffff; }
  @media (max-width: 768px) {
    .footer {
      padding: 50px 0; } }
  .footer .wrap {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      .footer .wrap {
        flex-direction: column;
        align-items: center; } }
    .footer .wrap > div {
      width: 30%;
      padding: 0 50px;
      border-right: 1px solid #ffffff; }
      @media (max-width: 1023px) {
        .footer .wrap > div {
          border: 0;
          text-align: center;
          width: auto;
          padding: 0 0 50px 0;
          display: flex;
          flex-direction: column;
          align-items: center; } }
      .footer .wrap > div:first-child {
        width: 40%;
        padding-left: 0; }
        @media (max-width: 1023px) {
          .footer .wrap > div:first-child {
            width: auto; } }
      .footer .wrap > div:nth-child(2) {
        width: 25%; }
        @media (max-width: 1023px) {
          .footer .wrap > div:nth-child(2) {
            width: auto; } }
      .footer .wrap > div:last-child {
        width: 35%;
        border: 0;
        padding-right: 0; }
        @media (max-width: 1023px) {
          .footer .wrap > div:last-child {
            width: auto;
            padding-bottom: 0; } }
  .footer ul {
    padding: 0; }
    .footer ul li {
      list-style: none; }
  .footer a, .footer .contact-link {
    color: #ffffff;
    text-decoration: none;
    transition: all 0.25s;
    cursor: pointer; }
    .footer a:hover, .footer .contact-link:hover {
      color: #FFC822; }
  .footer__address {
    display: flex;
    align-items: center;
    margin-top: 20px; }
    .footer__address i {
      margin-right: 10px;
      font-size: 36px; }
    @media (max-width: 1023px) {
      .footer__address {
        transform: translateX(-20px); } }
  .footer__social {
    margin: 20px 0 40px; }
    .footer__social .social__item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 40px;
      background: #ffffff;
      color: #212529;
      font-size: 24px;
      transition: all 0.25s; }
      .footer__social .social__item:hover {
        text-decoration: none;
        color: #ffffff;
        background: #FFC822; }
  .footer__contact i {
    font-size: 20px;
    margin-right: 10px; }
  .footer .footer__links-bottom {
    text-align: center;
    font-size: 12px;
    color: #ffffff; }
    .footer .footer__links-bottom .wrap {
      display: block;
      margin-top: 50px; }
    .footer .footer__links-bottom a {
      color: #ffffff;
      text-decoration: none; }
      .footer .footer__links-bottom a:hover {
        text-decoration: underline; }

.swpm_full_page_protection_not_logged_msg {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 50px;
  text-align: center;
  min-height: calc(100vh - 570px); }
