.header{
    position:fixed;
    top:0;
    left:0;
    z-index:$z-header;
    width:100%;
    background:transparent;
    transition: all 0.2s;
    &.active{
        background: $grey-dark;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    @media (max-width: $desksm) {
        background: $grey-dark;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    }
    .wrap{
        position:relative;
        display:flex;
        align-items: center;
        justify-content: space-between;
        height:90px;
        max-width :100%;
        @media (max-width: $mob) {
            height:60px;
        }
    }

    .logo{
        margin-right:50px;
    } 
    
    .menu-burger {
        display: none;
        cursor: pointer;
        transition: all 0.25s;
        font-size: 35px;
        color:$white;
        &:hover {
            color: $primary;
            @media (max-width: $tab) {
                color: $white;
            }
        }

        @media (max-width: $desksm) {
            display: flex;
            width: 50px;
            height: 50px;
            align-items: center;
            justify-content: flex-end;
        }

        .menu-open {
            display: block;
        }

        .menu-close {
            display: none;
        }

        &.active {
            .menu-open {
                display: none;
            }

            .menu-close {
                display: block;
            }
        }
    }

    .main-menu{
       display: flex;
       align-items: center;
        .menu__content{
            display: flex;
            align-items: center;
            @media (max-width: $desksm) {
                display:none;
                position:fixed;
                top:90px;
                left:0;
                flex-direction: column;
                width:100%;
                background:$grey-dark;
                overflow:hidden;
                transition:all 0.25s;
                border-bottom:1px solid $black;
            }
            @media (max-width: $mob) {
                top:60px;
            }
            &.open{
                display: block;
            }
            
            .menu{
                display: flex;
                align-items: center;
                list-style: none;
                padding:0;
                margin:0;
                @media (max-width: $desksm) {
                    flex-direction: column;
                }
                li{
                    margin:0 30px 0 0;
                    @media (max-width: $desksm) {
                        margin:20px 0;
                        text-align: center;
                    }
                    a, .contact-link{
                        position:relative;
                        color:$white;
                        text-decoration: none;
                        transition: all 0.25s;
                        font-size:17px;
                        cursor:pointer;
                        @media (max-width: $desksm) {
                            font-size:22px;
                        }
                        &:after{
                            position:absolute;
                            top:100%;
                            left:0;
                            display:block;
                            content:'';
                            width:0;
                            height:2px;
                            background:$primary;
                            transition: all 0.25s;
                        }
                        &:hover, &.active{
                            &:after{
                                width:100%;
                            }
                        }
                    }
                    &.current_page_item{
                        a:after{
                            width:100%;
                        }
                    }
                }
                .menu-item-has-children{
                    &.current_page_item{
                        .sub-menu{
                            a{
                                &:after{
                                    width:0;
                                }
                                &:hover{
                                    &:after{
                                        width:100%;
                                    }
                                }
                            }
                            .current_page_item{
                                a:after{
                                    width:100%;
                                } 
                            }
                        }
                    }
                    .sub-menu{
                        position:absolute;
                        transform: translateX(-20px);
                        visibility: hidden;
                        opacity: 0;
                        transition : all 0.25s;
                        background-color: $white;
                        z-index: $z-menu;
                        list-style: none;
                        padding:20px;
                        box-sizing: border-box;
                        border-radius: 10px;
                        overflow: hidden;
                        @media (max-width: $desksm) {
                            position:relative;
                            visibility: visible;
                            opacity: 1;
                            height:auto;
                            text-align: center;
                            padding:0;
                             transform: translateX(0);
                        }
                        .menu-item{
                            margin-top:20px ;
                            &:first-child{
                                margin-top:0;
                                @media (max-width: $desksm) {
                                    margin-top:20px ;
                                }
                            }
                        }
                    }
                    & > a{
                        &:hover{
                            &:after{
                                width:0;
                            }
                            
                        }
                    }
                    &:hover{
                        .sub-menu{
                            visibility: visible;
                            opacity: 1;
                            @media (max-width: $desksm) {
                                height:auto;
                            }
                        }
                    }
                     
                    
                    
                }
            }
            
            .link-cart{
                color:$grey-dark;
                transition: all 0.25s;
                margin: 0 30px 0 0;
                &:hover{
                    color:$primary;
                }
                @media (max-width: $desksm) {
                    display:none;
                }
            }
            .button{
                @media (max-width: $desksm) {
                    margin:20px 0 30px 0;
                    font-size:22px;
                }
            }
        }
    }
}

