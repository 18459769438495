.main-wrap {
  width: 100%;
 //max-width: 1920px;
  margin: auto;
}
.wrap {
  width: 100%;
  max-width: 1340px;
  margin: auto;
  padding: 0 30px;
  box-sizing: border-box;
  @media (max-width: $mob) {
    padding: 0 15px;
  }
}
