.footer{
    
    padding: 50px 0;
	@media (max-width: $mob) {
		padding: $space-mobile 0;
	}
    background:$grey-dark;
    color:$white;
    .wrap{
        display: flex;
        justify-content: space-between;
        @media (max-width: $tab) {
            flex-direction: column;
            align-items: center;
        }
        & > div{
            width:30%;
            padding:0 50px;
            border-right:1px solid $white;
            
            @media (max-width: $tab) {
                border:0;
                text-align:center;
                width:auto;
                padding:0 0 50px 0;
                display:flex;
                flex-direction: column;
                align-items: center;
            }
            &:first-child{
                width:40%;
                padding-left:0;
                @media (max-width: $tab) {
                    width:auto;
                }
            }
            &:nth-child(2) {
                width: 25%;
                @media (max-width: $tab) {
                    width: auto;
                }
            }
            &:last-child{
                width: 35%;
                border:0;
                padding-right:0;
                @media (max-width: $tab) {
                    width:auto;
                    padding-bottom:0;
                }
            }
        }
    }
    ul{
        padding:0;
        li{
            list-style: none;
        }
    }
    a, .contact-link{ 
        color:$white;
        text-decoration:none;
        transition:all 0.25s;
        cursor:pointer;
        &:hover{
            color:$primary;
        }
    }
    &__address{
        display: flex;
        align-items: center;
        margin-top:20px;
        i{
            margin-right:10px;
            font-size:36px;
        }
        @media (max-width: $tab) {
            transform: translateX(-20px);
        }
    }
    &__social{
        margin:20px 0 40px;
        .social__item{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width:40px;
            height:40px;
            margin-right:10px;
            border-radius:40px;
            background:$white;
            color:$grey-dark;
            font-size:24px;
            transition:all 0.25s;
            &:hover{
                text-decoration:none;
                color:$white;
                background:$primary;
            }

        }
    }
    &__contact{

        i{
            font-size:20px;
            margin-right:10px;
        }
    }
    .footer__links-bottom{
        text-align:center;
        font-size:12px;
        color:$white;
        .wrap{
            display: block;
            margin-top:50px;
        }
        a{
            color:$white;
            text-decoration:none;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}