.centres-formations {
  background: url(../images/centre-formations--white.jpg) no-repeat center $grey-light;
  background-attachment: fixed;
  padding: $space 0;
  text-align: center;

  @media (max-width: $mob) {
    padding: $space-mobile 0;
    background: $grey-light;
  }

  .wrap {
    max-width: 900px;
  }

  p {
    font-size: 22px;
  }

  .button {
    margin-top: 13px;
  }


}