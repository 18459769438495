.noscroll {
  overflow-y: hidden;
  margin-right:17px;
  @media (max-width: $tab) {
    margin-right:0;
  }
}

.no-margin{
  margin: 0 !important;
}

.center {
  text-align: center;
}
.justify-center{
  justify-content: center;
}

[v-cloak] {
  display: none;
}

.uppercase{
  text-transform:uppercase;
}

.disabled{
  color:$grey;
}

.hide-tablet{
  @media (max-width: $tab) {
    display: none !important;
  }
}
.hide-mobile{
  @media (max-width: $mob) {
    display:none !important;
  }
}
.show-tablet {
  display:none;
  @media (max-width: $tab) {
    display: block !important;
  }
}
.show-mobile {
  display: none;
  @media (max-width: $mob) {
    display: block !important;
  }
}

.grey-light {
  color: $grey-light;
}
.grey-dark {
  color: $grey-dark;
}
.white{
  color: $white;
}
.primary{
  color: $primary;
}

.bg-grey-dark{
  background:$grey-dark;
}
.bg-grey-light {
  background: $grey-light;
}
.bg-primary {
  background: $primary;
}
.bg-secondary {
  background: $secondary;
}

.bg-grey-light-crypto{
  background: url(../images/bg-crypto--light.gif) repeat $grey-light;
  background-size:500px;
  background-attachment: fixed;
}



.rounded{
  border-radius:10px;
}

.floating{
  animation: floating 6s ease-in-out infinite;
  @media (max-width: $tab) {
    animation : none;
  }
}
@keyframes floating {
  0% {

    transform: translatey(0px);
  }

  50% {

    transform: translatey(-40px);
  }

  100% {
    transform: translatey(0px);
  }
}

.text-big{
  font-size:22px;
}

.img-grey{
  filter: grayscale(1);
}