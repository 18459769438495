.animate{
  opacity:0;
  transition: all 0.25s;
  &.to-top{
    transform: translateY(100px);
  }
  &.to-bottom {
    transform: translateY(-100px);
  }
  &.to-left {
    transform: translateX(-100px);
  }
  &.to-right {
    transform: translateX(100px);
  }

  &.animated{
    opacity: 1;
    transform: translate(0);
  }

  @media (max-width: $mob) {
    opacity: 1;
    transform: translate(0);
  }
  
}