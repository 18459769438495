$white: #ffffff;
$black: #000000;

$grey-dark : #212529;
$grey : rgb(106, 106, 106);
$grey-light : rgb(249, 250, 251);

$primary : #FFC822;
$primary-dark : #ffaa00;

$secondary : #ff9f22;

$green : #42be35;
$green-light : #f0fff0;

$red :#bd0000; 
$red-light : #fff0f0;