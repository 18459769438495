.title{
    &-xl{
        font-size:60px;
        line-height:1.2;
        font-weight: 700;
        @media (max-width: $tab) {
            font-size: 40px;
        }
        @media (max-width: $mob) {
            font-size: 36px;
        }
    }

    &-l {
        font-size: 40px;
        line-height: 1.2;
        font-weight: 700;
        text-transform:uppercase;
        @media (max-width: $mob) {
            font-size: 32px;
        }
    }
    &-m {
        font-size: 34px;
        line-height: 1.2;
        font-weight: 700;
        text-transform: uppercase;
    }

    &-s {
        margin-top: 50px;
        font-size: 28px;
        line-height: 1.2;
        font-weight: 700;
        &:first-child{
            margin-top: 0;
        }
        @media (max-width: $mob) {
            font-size: 24px;
        }
    }

    &-xs {
        margin-top: 35px;
        font-size: 22px;
        line-height: 1.2;
        font-weight: 700;
        @media (max-width: $mob) {
            font-size: 20px;
        }
        &:first-child{
            margin-top: 0;
        }
    }
}