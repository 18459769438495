.page-template-formation,
.single-formation-video{
   padding:0;
    .header {
        display: none;
      }
}

.template-formation{

  &__aside{
    position:fixed;
    top:0;
    left:0;
    width:350px;
    height:100vh;
    background: $grey-dark;
    box-shadow: 3px 10px 9px 0px rgba(0, 0, 0, .3);
    @media (max-width: $desksm) {
      transition:all 0.2s;
      left: -350px;
      z-index:2;
      box-shadow: 3px 10px 9px 0px rgba(0, 0, 0, 0);
    }
    @media (max-width: $mob) {
      width: 320px;
      left: -320px;
    }
    &.open{
      @media (max-width: $desksm) {
        left: 0;
        box-shadow: 3px 10px 9px 0px rgba(0, 0, 0, .3);
      }
    }
    .aside{
      &__button{
        position:absolute;
        top:0;
        right:-40px;
        display:none;
        justify-content: center;
        align-items:center;
        width:40px;
        height:40px;
        background:$grey-dark;
        color:$white;
        cursor: pointer;
        transition: all 0.2s;
        &:hover{
          background:$primary;
        }
        @media (max-width: $desksm) {
          display:flex;
        }
        .button__close{
          display:none;
        }
        &.open {
          .button__open {
            display: none;
          }
          .button__close {
            display: block;
          }
        }
      }
      &__logo {
          padding: 0 15px;
          display: flex;
          align-items: center;
          height: 90px;
        }
      &__retour{
        margin:10px 15px 20px 15px;
        a{
          color:$white;
          font-size:15px;
          text-decoration: none;
          transition: all 0.2s;
          &:hover{
            color: $primary;
          }
        }
      }
      &__sommaire{
        overflow-y: auto;
        height: calc(100vh - 190px);
        color:$white;
        margin:15px;
        padding:15px;
        border-radius:6px;
        background:lighten($grey-dark, 4%);
        .menu{
          list-style: none;
          padding-left:0;
          a{
            color: white;
            text-decoration: none;
            transition: all 0.2s;
            font-weight:600;
            &:hover{
              color: $primary;
            }
          }
          li{
            margin-bottom:14px;
            font-size:15px;
            &.current-menu-item{
              & > a{
                color:$primary;
              }
            }
            .sub-menu{
              li{
                margin: 4px 0;
                font-size: 14px;
                a{
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  } 
  &__main{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-left: 350px;
    padding:50px 30px 100px;
    @media (max-width: $desksm) {
        margin-left: 0;
    }
    @media (max-width: $mob) {
      padding: 50px 15px 100px;
    }
    &--intro{
      height: 100vh;
      text-align: center;
      .main__content .content__title{
        text-align:center !important;
      }
      .content__num{
        width: 70px;
        height: 70px;
        border-radius: 100px;
        color: white;
        background: $primary;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        margin-bottom: 40px;
        @media (max-width: $mob) {
          width: 50px;
            height: 50px;
            font-size:24px;
            margin-bottom: 30px;
        }
      }

    }
    .main__content{
      max-width: 1000px;
      width: 100%;
      margin:auto;
      .content {
        &__title {
          text-align: left;
          span {
            font-weight: 600;
          }
        }
        &__video {
          position: relative;
          width: 100%;
          margin-top: 40px;
          aspect-ratio: 16 / 9;
    
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
        &__text{
          margin-top: 40px;
        }
        &__quiz{
          margin: 50px 30px;
          padding:30px 0 20px ;
          background: $grey-light;
          border-radius:10px;
          @media (max-width: $mob) {
            margin: 50px 0;
            padding: 30px 15px 20px;
          }
          .quiz__item{
            padding: 0 30px;
            .item{
              &__numero{
                text-transform: uppercase;
                font-weight:600;
                color:$primary;
                font-size:16px;
                @media (max-width: $mob) {
                  font-size:14px;
                }
              }
              &__question{
                font-size:25px;
                font-weight:bold;
                margin-bottom:25px;
                @media (max-width: $mob) {
                  font-size: 21px;
                }
              }
              &__proposition{
                position:relative;
                margin-top:15px;
                
                .proposition__label{
                  position:relative;
                  display: block;
                  font-size: 18px;
                  background: $white;
                  border:1px solid $white;
                  padding: 20px 50px 20px 15px;
                  border-radius: 10px;
                  transition : all 0.2s;
                  @media (max-width: $mob) {
                    padding: 13px 50px 13px 10px;
                    font-size: 16px;
                  }
                  &:after{
                    content: "\f00d";
                    position: absolute;
                    top:0;
                    right:0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width:50px;
                    height:100%;
                    font-family: "Font Awesome 6 Free";
                    font-weight: 900;
                    color: $red;
                    font-size:30px;
                    opacity: 0;
                    transition: all 0.2s;
                    @media (max-width: $mob) {
                      width: 40px;
                      font-size: 22px;
                    }
                  }
                  &.true {
                    &:after {
                      content: "\f00c";
                      color: $green;
                    }
                  }
                }
                label{
                  position:absolute;
                  top:0;
                  left:0;
                  width: 100%;
                  height:100%;;
                  cursor: pointer;
                }
                input{
                  position: relative;
                  display:block;
                  margin-right:10px;
                  appearance: none;
                  cursor: pointer;
                  &:checked{
                   & + .proposition__label{
                    background: $red-light;
                    color: $red;
                    border-color:$red;
                    &:after {
                      opacity: 1;
                    }
                    &.true{
                      background: $green-light;
                      color: $green;
                      border-color: $green;
                      &:after {
                        opacity: 1;
                      }
                    }
                   }
                  }
                }
              }
              &__button{
                margin-top:50px;
              }
              &__end{
                display:flex;
                flex-direction:column;
                align-items: center;
                .end{
                  &__icon{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 140px;
                    height: 140px;
                    border-radius: 150px;
                    background: #fff;
                    border: 4px solid $primary;
                    font-size: 60px;
                    
                  }
                  &__title{
                    margin-top:20px;
                    font-size:34px;
                    font-weight: bold;
                  }
                  &__button{
                    margin:30px 0 15px;
                  }
                }
              }
            }

          }
          .quiz__nav {
            position:relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
            padding: 20px 30px 0 30px;
            border-top: 1px solid lighten($grey-dark, 60%);
            .swiper-button-disabled{
              opacity:0;
            }
            .hidden{
              opacity:0;
              visibility: hidden;
            }
            .nav__prev{
              @media (max-width: $mob) {
                margin-left: 12px;
              }
              span {
                  @media (max-width: $mob) {
                    display: none;
                  }
                }
            }
            .button{
              span{
                @media (max-width: $mob) {
                  display:none;
                }
              }
              i {
                @media (max-width: $mob) {
                  margin:0;
                }
              }
            }
            .nav__bullets {
              position:absolute;
              left: calc(50% - 48px);
              display: flex;
              @media (max-width: $mob) {
                left: calc(50% - 36px);
              }
              .swiper-pagination-bullet.swiper-pagination-bullet-active{
                background:$primary;
              }
              .bullet {
                width: 8px;
                height: 8px;
                margin: 0 3px;
                border-radius: 10px;
                background: $white;
                border: 1px solid lighten($grey-dark, 60%);
                transition: all 0.2s;
                cursor: pointer;
                @media (max-width: $mob) {
                  margin: 0 1px;
                }
                &.active,
                &:hover {
                  border-color: $primary;
                  ;
                  background: $primary;
                }
              }
            }
          }
        }
      }
    }
   
  }
    &__nav {
      position: fixed;
      bottom: 0;
      left: 350px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 350px);
      box-sizing: border-box;
      height: 80px;
      padding: 0 25px;
      background-color: $white;
      color: $white;
      @media (max-width: $desksm) {
        left:0;
        width: 100%;
      }
      @media (max-width: $mob) {
        padding: 0 10px;
      }
      .nav__title{
        margin:0 15px;
        color:$grey-dark;
        font-weight:600;
        text-align:center;
        @media (max-width: $mob) {
          font-size:13px;
        }
        div{
          font-size:12px;
          @media (max-width: $mob) {
            font-size: 10px;
          }
        }
      }
      .button{
        span{
          @media (max-width: $tab) {
            display:none;
          }
        }
        i{
          @media (max-width: $tab) {
            margin:0;
          }
        }
      }
    }
}