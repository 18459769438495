.pdv{
  .wrap{
    
  }
  &__intro{
    padding: 90px 0 50px 0;
    background:$grey-dark;
    @media (max-width: $mob) {
      padding: 70px 0 35px 0;
    }
    .intro{
      &__suptitle {
        color: $primary;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 15px;
      }
      &__text{
        margin-top:15px;;
        font-size: 20px;
        color: $white;
        @media (max-width: $mob) {
            font-size: 18px;
          }
      }
      &__maj {
        margin-top: 20px;
        font-size: 14px;
        color: $white
      }
    
      &__title {
        text-transform: uppercase;
        font-size: 46px;
        color: white;
    
        @media (max-width: $mob) {
          font-size: 32px;
        }
    
        @media (max-width: 375px) {
          font-size: 30px;
        }
      }
    }
  }
  &__content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__left{
    width:calc(100% - 480px);
    @media (max-width: $tab) {
      width: 100%;
    }
    .title--3{
      margin-top:50px;
      &:first-child{
        margin-top:0;
      }
    }
    p{
      
    }
    .check{
      position:relative;
      padding-left:30px;
      &:before{
        position:absolute;
        top:1px;
        left:0;
        font-family: "Font Awesome 6 Free";
        content: "\f00c";
        font-weight: 900;
        color:$green
      }
    }
  }
  &__right{
    width:400px;
    margin-top:-310px;
    margin-bottom: -200px;
    @media (max-width: $tab) {
      width: 100%;
      margin-top: 50px;
      margin-bottom: auto;
    }
  }
  
  &__video {
    margin-top: 50px;

    iframe,
    video {
      display: block;
      width: 100%;
      height: 450px;
      border-radius: 10px;
      border: 0;
      @media (max-width: $tab) {
        height: 50vw;
      }
    }
  }



  &__informations{
    position:sticky;
    top:120px;
    padding:20px;
    background:$white;
    box-shadow:0 0 10px 0 rgba(0, 0, 0, .2);
    border-radius:10px;
    @media (max-width: $tab) {
      position: relative;
      top:0;
    }
    .informations{
      &__sep{
        display: block;
        width: 150px;
        margin: 20px auto;
        border-bottom: 1px solid rgba($black, 0.3); 
      }
      &__title{
        font-weight:bold;
        text-align:center;
        font-size:22px;
      }
      &__price{
        margin-bottom:-15px;
        text-align:center;
        color: $primary;
        font-weight: 700;
        font-size: 60px;
        sup{
          font-size: 24px;
        }
        .prix-barre{
          color:$grey-dark;
          font-size:30px;
          text-decoration: line-through;
          font-weight:600;
        }
      }
      &__list{
        .list__item{
          display:flex;
          margin-bottom:8px;
          .item__icon{
            width:35px;
          }
        }
      }
      &__start{
        text-align:center;
        .start{
          &__ou{
            margin:8px 0 0 0;
            font-size:14px;
          }
          &__free{
            font-size:16px;
            a{
              color:$primary;
            }
          }
        }
      }
    }
    &-mobile{
      display:none;
      @media (max-width: $tab) {
        display:flex;
        align-items: center;
        justify-content: space-between;
        position:fixed;
        bottom:0;
        left:0;
        width:100%;
        height:75px;
        box-sizing: border-box;
        padding:0 15px;;
        background:$white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
        z-index:2;
      }
      .button{
        margin-left:15px;;
      }
      .informations-mobile{
        &__title {
            font-weight: 600;
            font-size: 16px;
          }
          &__price {
            margin-top:-5px;;
            color: $primary;
            font-weight: bold;
            font-size:26px;
            sup {
              text-transform:uppercase;
              font-size: 11px;
            }
            .prix-barre {
              color: $grey-dark;
              font-size: 18px;
              text-decoration: line-through;
              font-weight: 600;
            }
          }
      }
      
    }
  }
  
  
}