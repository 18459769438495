.chapo{
  text-align:center;
  .wrap{
    max-width:860px;
  }
  &__title{
    font-size:36px;
  }
  p{
    font-size:22px;
    @media (max-width: $mob) {
			font-size:19px;
		}
  }
}