.button{
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    height: 35px;
    margin-right:20px;
    box-sizing: border-box;
    padding: 5px 15px;
    background: $primary;
    border: 2px solid $primary;
    border-radius: 8px;
    font-family: $font-1;
    color: $grey-dark;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    &:last-child{
        margin-right:0;
    }
    &:hover{
        background:$primary-dark;
        border-color:$primary-dark;
    }
    &--outline{
        background:transparent;
        color:$primary;
        &:hover{
            color: $grey-dark;
        }
    }
    &--black{
        background: $black;
        border-color: $black;
        color: $white;
        &:hover {
            color: $grey-dark;
        }
    }
    &--grey {
        background: $grey-dark;
        border-color: $grey-dark;
        color: $white;
        &:hover {
            color: $grey-dark;
        }
    }
    &--grey-outline{
        background: transparent;
        border-color: $grey-dark;
        color: $grey-dark;
    }

    &--l{
        height:50px;
        padding: 8px 25px;
        font-size: 20px;
        @media (max-width: $mob) {
            padding: 5px 15px;
        }
    }
    &--xl {
        height: 65px;
        padding: 10px 35px;
        font-size:24px;
    }

}

.buttons-container{
    margin-top:25px;
    .button:first-child{
        @media (max-width: $mob) {
            margin-bottom:10px;
        }
    }
    .button:last-child {
        @media (max-width: $mob) {
            margin-bottom: 0;
        }
    }
   
}