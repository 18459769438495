.separator{
    height:80px;
    @media (max-width: $mob) {
        height:55px
    }
    &--md {
        height: 65px;
        @media (max-width: $mob) {
            height: 40px;
        }
    }
    &--sm{
        height: 50px;
        @media (max-width: $mob) {
            height: 30px;
        }
    }
    &--xsm {
        height: 30px;

    }
}