.citation{
  position:relative;
  padding:0 60px;
  @media (max-width: $mob) {
    padding:0 0 0 30px;
  }
  &::before{
    position:absolute;
    left:0;
    top:-25px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content:"\f10d";
    font-size:40px;
    @media (max-width: $mob) {
      top:-12px;
      font-size:25px;
    }
  }
  &__text{
    font-size:22px;
    font-style: italic;
    @media (max-width: $mob) {
      font-size:19px;
    }
    p{
      margin-top:0;
    }
  }
  &__author{
    font-size:24px;
    font-weight: bold;
    @media (max-width: $mob) {
      font-size:20px;
    }
  }
}