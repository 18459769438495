@font-face {
  font-family: 'font-icons';
  src: url('../fonts/font-icons.woff2?2044915') format('woff2'), url('../fonts/font-icons.woff?2044915') format('woff');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
	  font-family: 'font-icons';
	  src: url('../font/font-icons.svg?2044915#font-icons') format('svg');
	}
  }
  */
[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'font-icons';
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-search:before { content: '\e800'; } /* '' */
.icon-close:before { content: '\e801'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
