.hero{
    overflow: hidden;
    background: url(../images/bg-3.jpg) no-repeat center top;
    background-attachment: fixed;

    .hero__overlay{ 
        padding-top: 50px;
        background-color: rgba(0, 0, 0, .8);
        height: 530px;
        display: flex;
        align-items: center;
    }
    .hero__content{
        position:relative;
        display:flex;
        align-items: center;
        .content__text{
            
            box-sizing: border-box;
            padding-right:40px;
            color: $white;
            p{
                font-size: 20px;
                max-width: 780px;
                color: $white;
                @media (max-width: $mob) {
                    font-size: 16px;
                }
            }
            .text__buttons{
                margin-top:40px;
                .button{
                    &:first-child{
                        margin-right:20px;
                        @media (max-width: $mob) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
        .content__img {
            position:absolute;
            top: 0;
            right:0;
            width: 30%;
            box-sizing: border-box;
            padding-left: 40px;
            img{
                display:block;
                width:100%;
                height:auto;
            }
        }
    }
}