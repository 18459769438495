.coach{
  &__content{
    display:flex;
    align-items: center;
    margin-top:50px;
    @media (max-width: $mob) {
      flex-direction: column;
    }
    .content{
      &__img{
        img{
          width: 200px;
          height:auto;
          border-radius: 108px;
          margin-right:50px;;
          border:4px solid $grey-light;
          @media (max-width: $mob) {
            margin:0 0 30px 0;
          }
        }
      }
      &__text{
        width: calc(100% - 258px);
        @media (max-width: $mob) {
          width: auto;
        }
      }
    }
  }
}