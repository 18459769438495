.page-404{
  .title{
    font-size:105px;
  }
  p{
    margin-top:20px;
    font-size:24px;
  }
  .button{
    margin-top: 50px;;
  }
}